'use strict'

export default {
  SET_CURRENT_RECORD: 'SET_CURRENT_RECORD',
  UPDATE_FIELDS: 'UPDATE_FIELDS',
  CLEAR_CURRENT_RECORD: 'CLEAR_CURRENT_RECORD',
  REFRESH_CURRENT_RECORD: 'REFRESH_CURRENT_RECORD',
  REFRESH_CURRENT_VIEW: 'REFRESH_CURRENT_VIEW',
  INCREMENT_NUM_PAGES_TO_SHOW: 'INCREMENT_NUM_PAGES_TO_SHOW',
  SET_DEFAULT_RECORD: 'SET_DEFAULT_RECORD',
  GO_TO_INDEX: 'GO_TO_INDEX',
  GO_TO_NEXT_PAGE: 'GO_TO_NEXT_PAGE',
  GO_TO_PREVIOUS_PAGE: 'GO_TO_PREVIOUS_PAGE',
  LOAD_PAGE: 'LOAD_PAGE',
  GET_RECORD_BY_INDEX_RESULT: 'GET_RECORD_BY_INDEX_RESULT',
  CURRENT_VIEW_UPDATED: 'CURRENT_VIEW_UPDATED',
  REVERT_CHANGES: 'REVERT_CHANGES',
  RECORD_REVERTED: 'RECORD_REVERTED',
  SAVE_RECORD: 'SAVE_RECORD',
  CALL_BEFORE_SAVE_HOOKS_RESULT: 'CALL_BEFORE_SAVE_HOOKS_RESULT',
  CHECK_HAS_DRAFT_RESULT: 'CHECK_HAS_DRAFT_RESULT',
  ASSERT_VALIDITY_RESULT: 'ASSERT_VALIDITY_RESULT',
  SAVE_RECORD_RESULT: 'SAVE_RECORD_RESULT',
  SEND_AUTOMATION_EVENT_RESULT: 'SEND_AUTOMATION_EVENT_RESULT',
  REMOVE_CURRENT_RECORD: 'REMOVE_CURRENT_RECORD',
  REMOVE_CURRENT_RECORD_RESULT: 'REMOVE_CURRENT_RECORD_RESULT',
  NEW_RECORD: 'NEW_RECORD',
  NEW_RECORD_RESULT: 'NEW_RECORD_RESULT',
  REFRESH: 'REFRESH',
}
