import { convertDataToDataBindingContract } from '../data/utils'

export default class StaticCache {
  constructor(routerPayload) {
    if (routerPayload && routerPayload.config) {
      //TODO: remove additional checks after TB stop passing memberData via routerData
      // https://wix.slack.com/archives/C0107T7BLSC/p1641982577002100
      this.#collectionId = routerPayload.config.dataset.collectionName
      this.#items = routerPayload.items
      this.#totalCount = routerPayload.totalCount
      this.#schemas = routerPayload.schemas
    }
  }

  getSchemas() {
    return this.#schemas
  }

  getDataStore() {
    if (this.#items) {
      return convertDataToDataBindingContract({
        items: this.#items,
        totalCount: this.#totalCount,
        collectionId: this.#collectionId,
      })
    }
  }

  getItems() {
    return this.#items
  }

  #collectionId
  #items
  #totalCount
  #schemas
}
