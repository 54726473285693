'use strict'
import { forEach, get } from 'lodash-es'
import baseAdapter from './baseAdapter'
import { selectCurrentRecord } from '../../dataset-controller/rootReducer'
import { AppError, VerboseMessage } from '../../logger'
import appContext from '../../viewer-app-module/DataBindingAppContext'

export default ({
  datasetApi,
  PresetVerboseMessage,
  getState,
  modeIsLivePreview,
}) => {
  const { logger, errorReporting } = appContext

  const getRows = (fetch, logGridValue) =>
    async function (startRow, endRow /*, sorting, filter*/) {
      const { items, totalCount } = await fetch(startRow, endRow - startRow)
      logGridValue(items)
      return {
        pageRows: items,
        totalRowsCount: totalCount,
      }
    }

  const logVerboseForBinding = grid => {
    const bindingDescription = {}

    grid.columns.forEach(({ label, dataPath, linkPath }) => {
      if (dataPath || linkPath) {
        bindingDescription[label] = Object.assign(
          dataPath ? { dataPath } : {},
          linkPath ? { linkPath } : {},
        )
      }
    })

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.BOUND, {
        component: grid,
        description: bindingDescription,
      }),
    )
  }

  const logVerboseValueDescription = component => items => {
    const valueDescription = []
    const columns = component.columns

    forEach(items, item => {
      const value = {}
      forEach(columns, column => {
        value[column.label] = get(item, column.dataPath)
      })
      valueDescription.push(value)
    })

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.FILLED, {
        component,
        description: valueDescription,
      }),
    )
  }

  return {
    ...baseAdapter,

    clearComponent({ component: grid }) {
      grid.rows = []
      grid.dataFetcher = undefined
    },

    bindToComponent({ component: grid }, actions) {
      // Synchronously set initial data for SEO rendering
      actions.getInitialData().chain(({ items }) => {
        grid.rows = items
      })

      const logGridValue = logVerboseValueDescription(grid)
      const record = selectCurrentRecord(getState())

      if (!modeIsLivePreview || record) {
        grid.dataFetcher = getRows(actions.fetch, logGridValue)
      }

      grid.onCellSelect(
        errorReporting(({ cellRowIndex }) => {
          datasetApi.setCurrentItemIndex(cellRowIndex)
        }, AppError.withMessage('Grid adapter onCellSelect failed')),
      )

      grid.onRowSelect(
        errorReporting(({ rowIndex }) => {
          datasetApi.setCurrentItemIndex(rowIndex)
        }, AppError.withMessage('Grid adapter onRowSelect failed')),
      )
      logVerboseForBinding(grid)
    },

    currentRecordModified({ component: grid }) {
      grid.refresh()
    },

    recordSetLoaded({ component: grid }) {
      grid.refresh()
    },

    currentViewChanged({ component: grid }) {
      grid.refresh()
    },
  }
}
