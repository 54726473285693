import { READ } from './readWriteModes'
import { DEFAULT_PAGE_SIZE } from './pagination'

export const collectionName = null
export const readWriteType = READ
export const filter = null
export const sort = null
export const includes = null
export const pageSize = DEFAULT_PAGE_SIZE
export const deferred = false
