import {
  get,
  isPlainObject,
  flow,
  map,
  pickBy,
  uniq,
  mergeWith,
} from 'lodash-es'
import {
  isFieldFromReferencedCollection,
  getReferenceFieldName,
  getFieldFromReferencedCollectionName,
} from '@wix/dbsm-common/src/reference-fields/fieldPath'

const mergeReferences = (firstValue, secondValue) => {
  if (firstValue !== secondValue) {
    return isPlainObject(firstValue) ? firstValue : secondValue
  }
}

const addItemsToCollection = (items, collection = {}) =>
  items.reduce((acc, record) => {
    const existingRecord = acc[record._id]
    acc[record._id] = mergeWith(existingRecord, record, mergeReferences)

    return acc
  }, collection)

const convertDataToDataBindingContract = ({
  items,
  totalCount,
  collectionId,
}) => ({
  recordInfosInDatasetOrder: [
    {
      itemIds: items.map(({ _id }) => _id),
      totalCount,
      collectionId,
    },
  ],
  recordsByCollectionId: { [collectionId]: addItemsToCollection(items, {}) },
})

const getFieldTypeCreator = (schema, relatedSchemas) => fieldName => {
  if (isFieldFromReferencedCollection(fieldName)) {
    const referenceFieldName = getReferenceFieldName(fieldName)
    const fieldFromReferencedCollectionName =
      getFieldFromReferencedCollectionName(fieldName)
    const referencedCollectionName =
      schema && schema.fields[referenceFieldName]
        ? schema.fields[referenceFieldName].referencedCollection
        : null
    const referencedSchema =
      relatedSchemas && referencedCollectionName
        ? relatedSchemas[referencedCollectionName]
        : null

    return get(referencedSchema, [
      'fields',
      fieldFromReferencedCollectionName,
      'type',
    ])
  }
  return get(schema, ['fields', fieldName, 'type'])
}

const getReferencedCollectionIds = schema => {
  return schema
    ? flow(
        fields =>
          pickBy(fields, ({ referencedCollection }) =>
            Boolean(referencedCollection),
          ),
        references =>
          map(references, ({ referencedCollection }) => referencedCollection),
        uniq,
        uniqueCollectionIds => uniqueCollectionIds.filter(Boolean),
      )(schema.fields)
    : []
}

const getFieldReferencedCollection = (fieldName, schema) =>
  schema != null && schema.fields[fieldName] != null
    ? schema.fields[fieldName].referencedCollection
    : null

const getSchemaDisplayField = schema =>
  schema != null ? schema.displayField : null

const getSchemaMaxPageSize = schema =>
  schema != null ? schema.maxPageSize : undefined

export {
  mergeReferences,
  convertDataToDataBindingContract,
  getFieldTypeCreator,
  getReferencedCollectionIds,
  getFieldReferencedCollection,
  getSchemaDisplayField,
  getSchemaMaxPageSize,
}
