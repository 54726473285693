'use strict'

import baseAdapter from './baseAdapter'
import {
  getComponentValueAccessorName,
  getComponentDefaultValue,
} from '../../helpers/componentValueUtils'
import { AppError } from '../../logger'
import appContext from '../../viewer-app-module/DataBindingAppContext'

export default () => {
  const { errorReporting } = appContext

  return {
    ...baseAdapter,

    bindToComponent({ component }, actions) {
      if (typeof component.onChange === 'function') {
        component.onChange(
          errorReporting(
            actions.refresh,
            AppError.withMessage('Filter input adapter onChange failed'),
          ),
        )
      }
    },

    resetUserFilter({ component }) {
      const componentAccessorName = getComponentValueAccessorName(component)
      component[componentAccessorName] = getComponentDefaultValue(component)
    },
  }
}
