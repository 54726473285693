import { Thenable } from '../helpers'
import { BI_SOURCE } from '@wix/dbsm-common/src/bi/constants'
import { getAppUrl } from '@wix/dbsm-common/src/worker/getUrl'
import getAppVersion from '@wix/dbsm-common/src/getAppVersion'
import { APP_NAME, APP_DEF_ID } from '../helpers/constants'
import biErrorCodes from '@wix/dbsm-common/src/bi/biErrorCodes'

export class Bi {
  static types = { EVENT: 'event', ERROR: 'error' }
  type = Bi.types.EVENT
  event
  constructor({
    id,
    collectionId,
    datasetId,
    datasetType,
    datasetMode,
    viewMode,
    pageId,
    pageUrl,
    sessionId,
  }) {
    this.event = {
      pageId,
      src: BI_SOURCE,
      ver: getAppVersion(getAppUrl(APP_NAME)),
      app_name: APP_NAME,
      app_id: APP_DEF_ID,
      evid: id,
      ds_id: datasetId,
      ds_type: datasetType,
      mode: datasetMode,
      collection_name: collectionId,
      viewmode: viewMode,
      page_url: pageUrl,
      ...(sessionId ? { vsi: sessionId } : {}),
    }
  }
}

export class BiError extends Bi {
  type = Bi.types.ERROR

  constructor({ message, code }) {
    super({})
    this.event = {
      evid: 10,
      dsc: message,
      errn: message,
      errc: code || biErrorCodes.ERROR,
    }
  }
}

export class Trace {
  static types = {
    START: 'START',
    END: 'END',
    TRACE: 'TRACE',
  }

  constructor(name, smth, params) {
    const { type, fn } =
      smth instanceof Function
        ? { type: Trace.types.TRACE, fn: smth }
        : { type: smth }

    this.name = name
    this.params = params
    this.run = () => this.#handlerByType[type](fn)
  }

  onStart = cb => (this.#start = cb)
  onEnd = cb => (this.#end = cb)

  #start = () => {}
  #end = () => {}

  #handlerByType = {
    [Trace.types.TRACE]: fn => {
      this.#start()

      return new Thenable(fn()).then(res => {
        this.#end()

        return res
      })
    },
    [Trace.types.START]: () => this.#start(),
    [Trace.types.END]: () => this.#end(),
  }
}

export class Breadcrumb {
  static with({
    category: presetCategory,
    message: presetMessage,
    level: presetLevel,
    data: presetData,
  }) {
    return class extends this {
      constructor({
        category = presetCategory,
        message = presetMessage,
        level = presetLevel,
        data,
      }) {
        super({
          category,
          message,
          level,
          data: data || presetData ? { ...data, ...presetData } : undefined,
        })
      }
    }
  }

  constructor({ category, message, level = 'info', data }) {
    this.event = { category, message, level, data }
  }
}

export class VerboseMessage {
  static types = {
    COMPONENT: {
      BOUND: 'COMPONENT_BOUND',
      FILLED: 'COMPONENT_FILLED',
    },
    DS_API: {
      CALLED: 'DS_API_CALLED',
      SUCCED: 'DS_API_SUCCED',
      DEPRECATED: 'DS_API_DEPRECATED',
      REGISTERED: 'DS_API_REGISTERED',
      TRIGGERED: 'DS_API_TRIGGERED',
    },
  }

  static with(context) {
    return class extends this {
      constructor(type, instanceContext = {}) {
        super(type, { ...context, ...instanceContext })
      }
    }
  }

  messages = []

  constructor(type, context) {
    this.#context = context
    this.messages = this.#builderByType[type](context)
  }

  #builderByType = {
    [VerboseMessage.types.COMPONENT.BOUND]: ({ collectionId, description }) =>
      description
        ? [
            `[Dataset - Connected] '${collectionId}' collection to element '${this.#getComponentPresentation()}':`,
            description,
          ]
        : [
            `[Dataset - Connected] '${collectionId}' collection to element '${this.#getComponentPresentation()}'`,
          ],
    [VerboseMessage.types.COMPONENT.FILLED]: ({
      collectionId,
      description,
    }) => [
      `[Dataset - Populated] '${collectionId}' collection into element '${this.#getComponentPresentation()}':`,
      description,
    ],
    [VerboseMessage.types.DS_API.CALLED]: ({ methodName, args }) =>
      args.length === 0
        ? [`[wix-dataset.${methodName}] called`]
        : [
            `[wix-dataset.${methodName}] called with (`,
            JSON.stringify(args),
            `)`,
          ],
    [VerboseMessage.types.DS_API.SUCCED]: ({ methodName, result }) =>
      result === undefined
        ? [`[wix-dataset.${methodName}] returned`]
        : [
            `[wix-dataset.${methodName}] returned with (`,
            JSON.stringify(result),
            `)`,
          ],
    [VerboseMessage.types.DS_API.DEPRECATED]: ({
      methodName,
      replacementMethodName,
    }) => {
      const replacementMessage = replacementMethodName
        ? `; use [wix-dataset.${replacementMethodName}] instead`
        : ''
      return [`[wix-dataset.${methodName}] is deprecated${replacementMessage}`]
    },
    [VerboseMessage.types.DS_API.REGISTERED]: ({ methodName }) => [
      `[${methodName} callback registered] on wix-dataset`,
    ],
    [VerboseMessage.types.DS_API.TRIGGERED]: ({
      eventName,
      eventArgs = [],
    }) => {
      if (!eventArgs.length) {
        return [`[${eventName} event] triggered on wix-dataset`]
      } else {
        const stringifiedArgs = eventArgs.map(smth =>
          smth instanceof Error ? smth.toString() : smth,
        )

        return [
          `[${eventName} event] triggered on wix-dataset with (`,
          JSON.stringify(stringifiedArgs),
          `)`,
        ]
      }
    },
  }

  #context = {}

  #getComponentPresentation() {
    const { component, parentId } = this.#context
    const compDescription = component.id ? `#${component.id}` : component.type

    return parentId ? `#${parentId}.${compDescription}` : compDescription
  }
}

export class ConsoleEvent {
  message
  level
  constructor(message, level = 'log') {
    this.message = message
    this.level = level
  }
}
