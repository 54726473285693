import baseAdapter from './baseAdapter'
import { transformFromRecordToView } from '../transformData'
import { VerboseMessage } from '../../logger'
import appContext from '../../viewer-app-module/DataBindingAppContext'

const adapter = ({ PresetVerboseMessage, modeIsLivePreview }) => {
  const { logger } = appContext
  const updateComponent = async (
    {
      connectionConfig: {
        properties: {
          options: { fieldName },
        },
      },
      component,
      role,
    },
    actions,
  ) => {
    const { items } = await actions.fetchAll()

    const options = items.reduce((acc, record) => {
      const value = transformFromRecordToView({
        value: record[fieldName],
        role,
      })

      if (value) acc.push({ value, label: value })

      return acc
    }, [])

    if (modeIsLivePreview && options.length === 0) return

    component.options = options

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.FILLED, {
        component,
        description: { options },
      }),
    )
  }

  return {
    ...baseAdapter,

    isValidContext({ connectionConfig: { properties = {} } }) {
      return Boolean(Object.keys(properties).length)
    },

    clearComponent({ component }) {
      component.options = []
    },

    bindToComponent({
      component,
      connectionConfig: {
        properties: {
          options: { fieldName },
        },
      },
    }) {
      logger.log(
        new PresetVerboseMessage(VerboseMessage.types.COMPONENT.BOUND, {
          component,
          description: { options: fieldName },
        }),
      )
    },

    currentRecordModified: updateComponent,
    recordSetLoaded: updateComponent,
  }
}

export default adapter
