import {
  getPaginationData as selectPaginationData,
  selectCurrentRecordIndex,
} from '../dataset-controller/rootReducer'

export const getPageSize = ({ state }) => {
  const { size } = selectPaginationData(state)
  return size
}

export const getPageOffset = ({ state }) => {
  const { offset } = selectPaginationData(state)
  return offset
}

export const getNumberOfItemsToShow = ({ state }) => {
  const { size, numPagesToShow } = selectPaginationData(state)
  return size * numPagesToShow
}

export const getCurrentItemIndex = ({ state }) => {
  const currentIndex = selectCurrentRecordIndex(state)
  return currentIndex == null ? null : currentIndex
}

export const getCurrentPageIndex = ({ state }) => {
  const currentIndex = getCurrentItemIndex({ state })
  if (currentIndex == null) {
    return null
  }
  const { size, offset, numPagesToShow } = selectPaginationData(state)
  return offset / size + numPagesToShow
}

export const getTotalItemCount = ({ recordStore }) =>
  recordStore().fold(
    () => null,
    service => service.getMatchingRecordCount(),
  )

export const getTotalPageCount = ({ state, recordStore }) => {
  const totalCount = getTotalItemCount({ recordStore })
  if (totalCount == null) {
    return null
  }
  return Math.ceil(totalCount / getPageSize({ state }))
}

export const canLoadMoreItems = ({ state, recordStore }) => {
  const totalCount = getTotalItemCount({ recordStore })
  if (totalCount == null) {
    return false
  }
  const offset = getPageOffset({ state })
  const items = getNumberOfItemsToShow({ state })
  return offset + items < totalCount
}

export const hasPreviousItem = ({ state }) => {
  const currentIndex = getCurrentItemIndex({ state })
  return currentIndex != null && currentIndex > 0
}

export const hasNextItem = ({ state, recordStore }) => {
  const totalCount = getTotalItemCount({ recordStore })
  if (totalCount == null) {
    return false
  }
  const currentIndex = getCurrentItemIndex({ state })
  return currentIndex != null && currentIndex < totalCount - 1
}

export const hasPreviousPage = ({ state }) => {
  const { offset } = selectPaginationData(state)
  return offset > 0
}

export const hasNextPage = ({ state, recordStore }) => {
  const totalCount = getTotalItemCount({ recordStore })
  if (totalCount == null) {
    return false
  }
  const { offset, size } = selectPaginationData(state)
  return offset + size < totalCount
}

export const getNewItemIndex = ({ state, atIndex, recordStore }) => {
  if (atIndex < 0) {
    return null
  }
  const totalCount = getTotalItemCount({ recordStore })
  if (totalCount == null) {
    return false
  }

  const currentIndex = getCurrentItemIndex({ state })
  const newIndex =
    atIndex != null ? atIndex : currentIndex == null ? 0 : currentIndex + 1

  return newIndex > totalCount ? null : newIndex
}

export const getNormalizedIndex = ({ recordStore, index }) => {
  const totalCount = recordStore().fold(
    error => {
      throw error
    },
    service => service.getMatchingRecordCount(),
  )
  return Math.max(Math.min(index, totalCount - 1), 0)
}
