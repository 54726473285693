'use strict'

import baseAdapter from './baseAdapter'
import { AppError } from '../../logger'
import appContext from '../../viewer-app-module/DataBindingAppContext'

const adapter = ({ controllerFactory, controllerStore }) => {
  const { errorReporting } = appContext

  const itemReady = compId => (scoped$w, itemData, index) => {
    const scopeInfo = { compId, itemId: itemData._id }
    const controller = controllerFactory.createDetailsController({
      scopeInfo,
      scoped$w: scoped$w.scoped,
    })
    controllerStore.setController(scopeInfo, controller)
    controller.pageReady()
  }

  const itemRemoved = compId => itemData => {
    const scopeInfo = { compId, itemId: itemData._id }
    controllerStore.removeController(scopeInfo)
  }

  return {
    ...baseAdapter,

    bindToComponent({ component, compId }) {
      component.onItemReady(
        errorReporting(
          itemReady(compId),
          AppError.withMessage('Details repeater adapter onItemReady failed'),
        ),
      )
      component.onItemRemoved(
        errorReporting(
          itemRemoved(compId),
          AppError.withMessage('Details repeater adapter onItemRemoved failed'),
        ),
      )
    },
  }
}

export default adapter
