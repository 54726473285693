export const DETAILS_REPEATER_ROLE = 'detailsRepeaterRole'
export const DETAILS_DATASET_ROLE = 'detailsDatasetRole' // Role for connecting to another dataset for filterByDataset
export const FILTER_INPUT_ROLE = 'filterInputRole' // Role for connecting component value to a filter parameter

// Roles for connecting components
export const TEXT_ROLE = 'textRole'

export const IMAGE_ROLE = 'imageRole'
export const TEXT_INPUT_ROLE = 'textInputRole'
export const BUTTON_ROLE = 'siteButtonRole'
export const ICON_BUTTON_ROLE = 'imageButtonRole'
export const CHECKBOX_ROLE = 'checkboxRole'
export const GALLERY_ROLE = 'galleryRole'
export const MEDIA_GALLERY_ROLE = 'mediaGalleryRole'
export const RATINGSDISPLAY_ROLE = 'ratingsDisplayRole'
export const RATINGSINPUT_ROLE = 'ratingsInputRole'
export const DROPDOWN_ROLE = 'dropdownRole'
export const DROPDOWN_OPTIONS_ROLE = 'dropdownOptionsRole'
export const TEXT_BOX_ROLE = 'textAreaRole'
export const RICH_TEXT_BOX_ROLE = 'richTextBoxRole'
export const GRID_ROLE = 'gridRole'
export const DATEPICKER_ROLE = 'datepickerRole'
export const RADIOGROUP_ROLE = 'radioGroupRole'
export const UPLOAD_BUTTON_ROLE = 'uploadButtonRole'
export const VIDEO_ROLE = 'videoRole'
export const VIDEOPLAYER_ROLE = 'videoPlayerRole'
export const VIDEOBOX_ROLE = 'videoBoxRole'
export const MUSICPLAYER_ROLE = 'musicPlayerRole'
export const REPEATER_ROLE = 'repeaterRole'
export const COLUMN_ROLE = 'columnRole'
export const PAGE_ROLE = 'pageRole'
export const MEDIACONTAINER_ROLE = 'mediaContainerRole'
export const PAGINATION_ROLE = 'paginationRole'
export const TOGGLESWITCH_ROLE = 'toggleSwitchRole'
export const SLIDER_ROLE = 'sliderRole'
export const ADDRESSINPUT_ROLE = 'addressInputRole'
export const GOOGLEMAP_ROLE = 'googleMapRole'
export const TIMEPICKER_ROLE = 'timePickerRole'
export const CHECKBOX_GROUP_ROLE = 'checkboxGroupRole'
export const STYLABLE_BUTTON_ROLE = 'stylableButtonRole'
export const PROGRESS_BAR_ROLE = 'progressBarRole'
export const VECTOR_IMAGE_ROLE = 'vectorImageRole'
export const SELECTION_TAGS_ROLE = 'selectionTagsRole'
export const SELECTION_TAGS_OPTIONS_ROLE = 'selectionTagsOptionsRole'
export const STRIP_ROLE = 'stripRole'
export const SECTION_ROLE = 'sectionRole'
export const SIGNATURE_INPUT_ROLE = 'signatureInputRole'
export const RICH_CONTENT_ROLE = 'richContentRole'
