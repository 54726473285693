'use strict'
import { forEach, unescape, values, isEmpty } from 'lodash-es'
import { TEXT_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
import {
  selectCurrentRecord,
  selectNextDynamicPageUrl,
  selectPreviousDynamicPageUrl,
} from '../../dataset-controller/rootReducer'
import {
  getFieldValue,
  shouldUpdateComponentFromRecord,
  setValueToComponent,
  getLogVerboseBindingDescription,
  getEmptyValueForFieldType,
} from './helpers'
import { transformFromRecordToView } from '../transformData'
import baseAdapter from './baseAdapter'
import { FieldType } from '@wix/wix-data-schema-types'
import appContext from '../../viewer-app-module/DataBindingAppContext'
import { DataBindingError, AppError } from '../../logger'
import { VerboseMessage } from '../../logger/events'

const { url } = FieldType

export default ({
  getState,
  datasetApi,
  eventListeners: { register },
  getFieldType,
  PresetVerboseMessage,
  wixFormatter,
  modeIsLivePreview,
  dispatcher,
}) => {
  const {
    logger,
    platform: { location, utils },
  } = appContext
  const getNavigateUrl = (navigate, record) => {
    if (navigate.fieldName) {
      return record[navigate.fieldName]
    }

    if (navigate.linkObject) {
      return utils.links.toUrl(navigate.linkObject)
    }
  }

  const navigateToDynamicPage = dynamicPageUrlState =>
    dynamicPageUrlState.matchWith({
      Empty() {},
      Loading() {},
      Loaded({ url }) {
        location.navigateTo(url)
      },
    })

  const bindActionsToComponentEvent = (events, component, actions) => {
    forEach(events, ({ action, postAction }, eventName) => {
      component[eventName](async () => {
        try {
          if (action === 'nextDynamicPage') {
            navigateToDynamicPage(selectNextDynamicPageUrl(getState()))
            return
          }
          if (action === 'previousDynamicPage') {
            navigateToDynamicPage(selectPreviousDynamicPageUrl(getState()))
            return
          }
          if (action === 'resetUserFilter') {
            actions.resetUserInputFilters()
            return
          }
          const record = await Promise.resolve(datasetApi[action]())
          if (postAction && postAction.navigate) {
            const url = getNavigateUrl(postAction.navigate, record)
            location.navigateTo(url)
          }
        } catch (e) {
          // error could have already been logged in datasetApi, so no need to log it again here
          // TODO: but if in some future the internal and external datasetApi's will be split
          // don't forget to properly log errors here.
          if (!(e instanceof DataBindingError)) {
            logger.log(
              new AppError(`${action} operation failed:`, { cause: e }),
            )
          }
        }
      })
    })
  }

  const applyBehaviorsToComponent = (behaviors, component) => {
    forEach(behaviors, ({ type: behavior }) => {
      let ignoreNextIndexChange = false
      switch (behavior) {
        case 'saveSuccessFeedback':
          register('beforeSave', () => component.hide())
          register('afterSave', () => {
            component.show()
            ignoreNextIndexChange = true
          })
          register('currentIndexChanged', () => {
            if (ignoreNextIndexChange) {
              ignoreNextIndexChange = false
            } else {
              component.hide()
            }
          })
          register('itemValuesChanged', () => component.hide())
          break
        case 'saveFailureFeedback':
          register('beforeSave', () => component.hide())
          register('currentIndexChanged', () => component.hide())
          dispatcher.subscribe('datasetSaveError', () => component.show())
          break
      }
    })
  }

  const handleBehaviors = (behaviors, component) => {
    forEach(behaviors, ({ type: behavior }) => {
      if (behavior === 'saveSuccessFeedback') {
        component.hide()
      }
    })
  }

  const convertTextIntoLink = component => {
    const compText = component.text
    component.text = `<a href=${compText} target="_blank" style="text-decoration: underline">${compText}</a>`
    const compHTML = component.html
    component.text = ''
    component.html = unescape(compHTML)
  }

  const updateComponentFromCurrentRecord = (
    { connectionConfig: { properties }, component, role },
    actions,
    updatedFields = [],
  ) => {
    const record = selectCurrentRecord(getState())
    if (!record) {
      return
    }
    const valueDescription = {}

    forEach(properties, ({ fieldName, format }, propPath) => {
      try {
        const fieldType = getFieldType(fieldName).getOrElse('')
        const value = transformFromRecordToView({
          value: getFieldValue(record, fieldName),
          role,
          fieldType,
          propPath,
          format,
          utils: {
            formatter: wixFormatter,
          },
        })
        valueDescription[propPath] = value

        if (shouldUpdateComponentFromRecord({ updatedFields, fieldName })) {
          setValueToComponent({
            component,
            propPath,
            value,
            fieldType,
            modeIsLivePreview,
          })

          if (fieldType === url && role === TEXT_ROLE) {
            convertTextIntoLink(component)
          }
        }
      } catch (e) {
        logger.log(new AppError(`Failed setting ${propPath}`, { cause: e }))
      }
    })

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.FILLED, {
        component,
        description: valueDescription,
      }),
    )
  }

  return {
    ...baseAdapter,

    isValidContext({ connectionConfig }) {
      return values(connectionConfig).find(configValue => !isEmpty(configValue))
    },

    clearComponent({ component, connectionConfig: { properties } }) {
      forEach(properties, ({ fieldName }, propPath) => {
        const fieldType = getFieldType(fieldName).getOrElse('')
        setValueToComponent({
          component,
          propPath,
          value: getEmptyValueForFieldType(fieldType),
          fieldType,
        })
      })
    },

    bindToComponent({ connectionConfig, component }, actions) {
      const { events, behaviors } = connectionConfig

      if (events) {
        bindActionsToComponentEvent(events, component, actions)
      }

      if (behaviors) {
        applyBehaviorsToComponent(behaviors, component)
      }

      logger.log(
        new PresetVerboseMessage(VerboseMessage.types.COMPONENT.BOUND, {
          component,
          description: getLogVerboseBindingDescription(connectionConfig),
        }),
      )
    },

    currentRecordModified(componentAdapterContext, actions, updatedFields) {
      updateComponentFromCurrentRecord(
        componentAdapterContext,
        actions,
        updatedFields,
      )
    },

    recordSetLoaded(componentAdapterContext, actions) {
      updateComponentFromCurrentRecord(componentAdapterContext, actions)

      const { behaviors } = componentAdapterContext.connectionConfig
      if (behaviors) {
        handleBehaviors(behaviors, componentAdapterContext.component)
      }
    },

    currentViewChanged(componentAdapterContext, actions) {
      updateComponentFromCurrentRecord(componentAdapterContext, actions)
    },

    currentIndexChanged(componentAdapterContext, actions) {
      updateComponentFromCurrentRecord(componentAdapterContext, actions)
    },
  }
}
